import React, { useState } from "react";
import Header from "./Header";
import Content from "./Content";
import Footer from "./Footer";
import StartScreen from "./StartScreen/StartScreen";
import rawStatements from "../statements.json";

function getHeader(statements, i) {
  if (statements && statements.length > i) {
    return statements[i].header;
  } else {
    return null;
  }
}

function getContent(statements, i) {
  if (statements && statements.length > i) {
    return statements[i].content;
  } else {
    return null;
  }
}

function increment(statements, i) {
  if (statements && statements.length > i) {
    incrementUse(statements[i]);
  }
}

const Main = () => {
  let [statements, setStatements] = useState();
  let [params, setParams] = useState();

  let [counter, setCounter] = useState(0);

  const next = () => {
    increment(statements, counter);
    setCounter(counter + 1);
  };

  const header = getHeader(statements, counter);
  const content = getContent(statements, counter);

  const applyParams = () => {
    const statements = filterStatements(rawStatements, params);
    setStatements(statements);
  };

  if (statements && counter < statements.length) {
    return (
      <div className="main">
        <Header text={header} total={statements.length} counter={counter + 1} />
        <Content text={content} />
        <Footer text={"Nächste"} function={next} />
      </div>
    );
  } else {
    if (counter > 0) {
      setCounter(0);
      setStatements(undefined);
    }
    return (
      <div className="main">
        <Header text="Neue Runde" total={0} counter={0} />
        <StartScreen onChange={setParams} statements={rawStatements.length} />
        <Footer text={"Start"} function={applyParams} />
      </div>
    );
  }
};

function filterStatements(rawStatements, params) {
  const filtered = rawStatements.filter(
    (s) => s.rating >= params.min && s.rating <= params.max
  );
  const shuffled = shuffle(filtered);
  const sorted = shuffled.sort((a, b) => timesUsed(a) - timesUsed(b));
  const out = sorted.slice(0, params.count);
  const shuffledOut = shuffle(out);
  const sortedShuffeled = shuffledOut.sort((a, b) => a.rating - b.rating);

  return sortedShuffeled;
}

function timesUsed(statement) {
  const storage = window.localStorage;
  if (storage) {
    const hash = hashCode(statement);
    const count = localStorage.getItem(hash);
    return count ? parseInt(count) : 0;
  } else {
    return 0;
  }
}

function incrementUse(statement) {
  const storage = window.localStorage;
  if (storage) {
    const hash = hashCode(statement);
    const count = localStorage.getItem(hash);
    localStorage.setItem(hash, (count ? parseInt(count) : 0) + 1);
  }
}

function hashCode(statement) {
  const str = JSON.stringify(statement);
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    var character = str.charCodeAt(i);
    hash = (hash << 5) - hash + character;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
}

/**
 * Shuffles array in place. ES6 version
 * @param {Array} a items An array containing the items.
 */
function shuffle(a) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

export default Main;
