import React from 'react';

const Footer = props => {
    return (
        <div className="footer">
            <button onClick={props.function}>{props.text} &gt;</button>
        </div>
    );
};

export default Footer;