import React, { useEffect, useState } from 'react';
import CategorySelector from './CategorySelector';
import Dial from './Dial';

const StartScreen = props => {

    const { statements, onChange } = props;

    const [min, setMin] = useState(0);
    const [max, setMax] = useState(5);
    const [count, setCount] = useState(10);

    useEffect(() => {
        if (onChange) {
            onChange({ min, max, count });
        }
    }, [min, max, count, onChange]);

    return (
        <div className="content">
            <table>
                <tbody>
                    <tr>
                        <td>Von:</td>
                        <td><CategorySelector max={max} selected={min} onSelect={setMin} /></td>
                    </tr>
                    <tr>
                        <td>Bis:</td>
                        <td><CategorySelector min={min} selected={max} onSelect={setMax} /></td>
                    </tr>
                    <tr>
                        <td>Anzahl:</td>
                        <td><Dial max={statements} increment={Math.floor(statements / 200) * 10} value={count} onChange={setCount} /></td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default StartScreen;