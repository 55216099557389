import React from 'react';

const CategorySelector = props => {

    const active = i => {
        const active = props.selected === i;
        return active.toString();
    }

    return (
        <div className="category-selector">
            <button active={active(0)} disabled={props.min > 0 || props.max < 0} onClick={() => props.onSelect(0)}>0</button>
            <button active={active(1)} disabled={props.min > 1 || props.max < 1} onClick={() => props.onSelect(1)}>1</button>
            <button active={active(2)} disabled={props.min > 2 || props.max < 2} onClick={() => props.onSelect(2)}>2</button>
            <button active={active(3)} disabled={props.min > 3 || props.max < 3} onClick={() => props.onSelect(3)}>3</button>
            <button active={active(4)} disabled={props.min > 4 || props.max < 4} onClick={() => props.onSelect(4)}>4</button>
            <button active={active(5)} disabled={props.min > 5 || props.max < 5} onClick={() => props.onSelect(5)}>5</button>
        </div>
    );
};

export default CategorySelector;