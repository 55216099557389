import React from 'react';

const Header = props => {
    return (
        <div className="header">
            {props.text || 'Ich hab noch nie'}...<span className="spacer" />[{props.counter}/{props.total}]
        </div>
    );
};

export default Header;