import React from 'react';

const Content = props => {
    return (
        <div className="content">
            ... {props.text || "'Ich hab noch nie' gespielt"}.
        </div>
    );
};

export default Content;