import React from 'react';

const Dial = props => {

    const value = props.value || 0;
    const max = props.max || 300;
    const increment = props.increment || 20;
    const onChange = props.onChange || (() => { });

    const inc = () => {
        const newVal = value + increment;
        onChange(Math.min(newVal, max));
    };
    const dec = () => {
        const newVal = value - increment;
        onChange(Math.max(newVal, 0));
    };

    return (
        <div className="dial">
            <button onClick={dec}>-</button><input type="test" value={value} readOnly={true} /><button onClick={inc}>+</button>
        </div>
    );
};

export default Dial;